import React, { ReactNode, useMemo } from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { FieldProps, getIn } from 'formik'
import cuid from 'cuid'

const noOp = value => value

const runAll = (arr, ...args) => {
  const result = arr.map(fn => fn(...args))
  return result.find(val => !!val)
}

type FieldInputProps = FieldProps &
  TextFieldProps & {
    normalize?: (str: string) => string
    selectOptions?: { value: string; label: string }[]
    showErrorMessage?: boolean
    warn?: ((value: any, fieldProps: FieldProps) => ReactNode)[]
  }

const FormikField = ({
  // Formik field props
  field,
  form,
  // Text field props
  children,
  label,
  helperText,
  variant,
  select,
  selectOptions,
  type,
  // Custom props
  normalize = noOp,
  warn,
  showErrorMessage = true,
  ...props
}: FieldInputProps) => {
  const { onChange: _, ...fieldProps } = field
  const { touched, errors, setFieldValue } = form
  const fieldTouched = getIn(touched, fieldProps.name)
  const error = getIn(errors, fieldProps.name)
  let helperTextContent, warning
  const id = useMemo(() => cuid(), [])

  if (select) {
    if (!selectOptions || !selectOptions.length) {
      throw new Error('You have to pass options to FormikField with select=true')
    }

    children = selectOptions.map(({ label: optionLabel, value }, key) => (
      <MenuItem key={key} value={value}>
        {optionLabel}
      </MenuItem>
    ))
  }

  // Show validation error if any
  if (fieldTouched && error && showErrorMessage !== false) {
    helperTextContent = error
    // Show warning if any
  } else if (fieldTouched && warn && (warning = runAll(warn, fieldProps.value, { field, form }))) {
    helperTextContent = warning
    // Default to helper text
  } else {
    helperTextContent = helperText
  }

  return (
    <TextField
      children={children}
      error={Boolean(fieldTouched && error)}
      id={id}
      helperText={helperTextContent}
      fullWidth
      margin='normal'
      label={label}
      type={type}
      select={select}
      variant={variant || ('outlined' as any)}
      onChange={ev => setFieldValue(fieldProps.name, normalize(ev.target.value))}
      {...fieldProps}
      {...props}
      inputProps={{
        'data-testid': fieldProps.name,
        ...props.inputProps,
      }}
    />
  )
}

export default FormikField
